<template>
  <article
    class="quiz"
  >
    <LazyQuizPHat />

    <LazyQuizPMain @show-help="showHelp = true" />
    <LazyQuizPHelp v-if="showHelp" />
  </article>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { textAggReplace } from '~/helpers'
import getTextAsync from '~/composible/getPageText'

definePageMeta({
  layout: 'empty-p',
})

const mainStore = useMainStore()
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
const metrikaId = computed(() => mainStore.getMetrikaId)
const uaId = computed(() => mainStore.getUaId)
const providerId = computed(() => mainStore.getProviderId)
const getAddressText = computed(() => cityStore.getAddressText)
const provider = computed(() => mainStore.getDomainConfig)
const showHelp = ref(false)
const textAsync = computed(() => data.value?.textAsync)

let errorStatus
let errorMessage
const { data, error } = await useAsyncData('pageQuizP', async () => {
  let textAsync

  const textAsyncFetch = async () => {
    try {
      textAsync = await getTextAsync({ url: '/{city}/quiz/' })
    }
    catch (e) {
      errorStatus = e.response.status
      errorMessage = e.message
      throw createError({
        statusCode: e.response.status,
        fatal: true,
        message: e.message,
      })
    }
    finally {
      //
    }
  }

  await Promise.all([textAsyncFetch()])

  return { textAsync }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}

const title = computed(() =>
  textAsync.value?.title
    ? textAggReplace(
        textAsync.value.title,
        getAddressText.value,
      )
    : '',
)
const description = computed(() =>
  textAsync.value?.description
    ? textAggReplace(
        textAsync.value.description,
        getAddressText.value,
      )
    : '',
)

useHead(() => ({
  title: title.value,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: description.value + '',
    },
  ],
  script: [
    {
      hid: 'og:title',
      property: 'og:title',
      content: title.value,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: provider.value
        ? `https://${provider.value?.domain}/android-icon-192x192.png`
        : 'https://dominternet.ru/android-icon-192x192.png',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: () => description.value + '',
    },
  ],
}))
</script>

<style scoped></style>
